<template>
  <CRow>
    <CCol col="12">
      <CCard v-for="(manager, id) of managers" :key="id">
        <CCardHeader @click="toggleCollapse(manager)" style="cursor: pointer;">
          {{ manager.manager.name }}
          <CIcon class="float-right" :name="manager.collapse ? 'cilChevronTop' : 'cilChevronBottom'" />
        </CCardHeader>
        <CCardBody body-wrapper>
          <CCollapse :show="manager.collapse" class="mt-2">
            <CRow>
              <CCol v-for="property of manager.properties" :key="'P_' + property.id" sm="4" md="3" lg="2">
                <CCard border-color="primary" class="property-card" @click="gotoProperty(property)">
                  <CCardHeader>({{ property.id }}) {{ property.short_name }}</CCardHeader>
                  <CCardBody background>
                    <img class="w-100" :src="property.logo || empty_image" @error="e => (e.target.src = empty_image)" />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCollapse>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'

export default {
  name: 'PropertySelector',
  mixins: [viewMixin],
  props: {
    type: String
  },
  data() {
    return {
      retry: null,
      collapse: {},
      managers: {},
      empty_image: require('@/assets/images/global/empty-image.jpg')
    }
  },
  created() {
    // TODO: use backend request instead and force refresh userExtraData
    this.interval = setInterval(() => {
      this.getManagers()
    }, 200)
  },
  methods: {
    getManagers() {
      //console.log('GETMANAGERS')
      if (!this.userExtra) return
      this.managers = {}
      // TODO: sort ?
      for (let property in this.userExtra.properties || []) {
        property = this.userExtra.properties[property]
        const manager = property.manager || { id: 0, name: '- No definido -' }
        if (this.managers[manager.id] === undefined) this.managers[manager.id] = { collapse: false, hack: '', manager, properties: [] }
        this.managers[manager.id].properties.push(property)
      }
      clearInterval(this.interval)
      this.interval = null
    },
    toggleCollapse(manager) {
      manager.manager.name += ' '
      manager.manager.name = manager.manager.name.trim()
      manager.collapse = !manager.collapse
    },

    gotoProperty(property) {
      const self = this
      self.$http.post((self.loggedUser.nouser ? '' : 'admin/') + `properties/set/${property.id}`, { id: property.id }).then(response => {
        console.log(response)
        self.$router.push({ name: 'Dashboard' })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.property-card {
  cursor: pointer;
}
</style>
